<template>
  <div>
    <h3 class="mb-4">
      {{ $t('payment.paymentsInProgressTab.title') }}
    </h3>
    <TableLoadingSkeleton v-if="loading" />
    <Table v-else :columns="columns" :data="tableData" show-index rounded border @row-click="handleRowClick">
      <template #filter-customerName>
        <el-input
          v-model="customerSearchFilter"
          :placeholder="$t('commons.searchWithDots')"
          class="text-typography-primary"
        >
          <template #prefix>
            <div class="d-flex align-items-center h-100">
              <SearchIcon width="20px" height="20px" />
            </div>
          </template>
        </el-input>
      </template>
      <template #cell-amount="{ rowData: { amount } }">
        <p>{{ formatMoney(amount) }}</p>
      </template>
      <template #filter-paymentMethod>
        <el-input
          v-model="paymentMethodSearchFilter"
          :placeholder="$t('commons.searchWithDots')"
          class="text-typography-primary"
        >
          <template #prefix>
            <div class="d-flex align-items-center h-100">
              <SearchIcon width="20px" height="20px" />
            </div>
          </template>
        </el-input>
      </template>
    </Table>
    <EventMapModal v-if="selectedPayment" :activity="selectedPayment" @close="selectedPayment = null" />
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { omit } from 'ramda';

import { Table, TableLoadingSkeleton } from '@/modules/core';
import { useBusinessByNames } from '@/modules/business/compositions/business';
import { SearchIcon } from '@/assets/icons';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import { usePaymentsInProgress } from '../compositions';
import { formatBilledDate } from '../../tools/formatters';

const TABLE_HEADER = {
  CUSTOMER_NAME: 'customerName',
  TOTAL_INVOICES: 'totalInvoices',
  INVOICE_DATE: 'invoiceDate',
  AMOUNT: 'amount',
  PAYMENT_METHOD: 'paymentMethod',
};

const getSort = ({ direction, column }) => {
  const { CUSTOMER_NAME, TOTAL_INVOICES, AMOUNT, PAYMENT_METHOD } = TABLE_HEADER;
  switch (column) {
    case CUSTOMER_NAME:
      return (a, b) => a[CUSTOMER_NAME].localeCompare(b[CUSTOMER_NAME]) * direction;

    case TOTAL_INVOICES:
      return (a, b) => (a[TOTAL_INVOICES] - b[TOTAL_INVOICES]) * direction;

    case AMOUNT:
      return (a, b) => (a[AMOUNT] - b[AMOUNT]) * direction;

    case PAYMENT_METHOD:
      return (a, b) => a[PAYMENT_METHOD].localeCompare(b[PAYMENT_METHOD]) * direction;

    default:
      return () => 0;
  }
};

export default {
  components: {
    Table,
    TableLoadingSkeleton,
    SearchIcon,
    EventMapModal: () => import('@/modules/eventMapModal/EventMapModal'),
  },
  props: {
    supplier: { type: Object, required: true },
    customerId: { type: String, default: null },
  },
  setup(props) {
    const { formatToCurrency } = useCurrency();

    const {
      loading: paymentsLoading,
      payments: allPaymentsInProgress,
      refetch: refetchPaymentsInProgress,
    } = usePaymentsInProgress(
      computed(() => ({
        supplierId: props.supplier.id,
        businessId: props.customerId ? props.customerId : undefined,
        completed: false,
        isCustomerSide: true,
      })),
      () => ({
        enabled: !!props.supplier.id,
      })
    );

    const customerIds = computed(() => allPaymentsInProgress.value.map(({ customerId }) => customerId));
    const { result: businesses, loading: businessesLoading } = useBusinessByNames({ businessIds: customerIds });

    const paymentsInProgress = computed(() =>
      allPaymentsInProgress.value.map((p) => ({
        ...omit(['requestedDate'], p),
        date: p.date || p.requestedDate,
        amount: p.amount * (p.isRefund ? -1 : 1),
      }))
    );

    const formatMoney = (value) => {
      if (typeof value === 'number' && !Number.isNaN(value)) {
        const number = Number(value.toFixed(2));
        const options = Number.isInteger(number) ? { maximumFractionDigits: 0 } : {};
        return formatToCurrency(value, options);
      }
      return '-';
    };

    return {
      formatMoney,
      paymentsInProgress,
      loading: computed(() => paymentsLoading.value),
      refetchPaymentsInProgress,
      businesses,
      businessesLoading,
      activeSort: ref({ direction: 1, column: TABLE_HEADER.CUSTOMER_NAME }),
      paymentMethodSearchFilter: ref(''),
      customerSearchFilter: ref(''),
      selectedPayment: ref(null),
    };
  },
  computed: {
    paymentsById() {
      return this.paymentsInProgress.reduce((idsMap, payment) => ({ ...idsMap, [payment.id]: payment }), {});
    },
    columns() {
      return [
        {
          header: this.$t('payment.paymentsInProgressTab.headers.customerName'),
          key: TABLE_HEADER.CUSTOMER_NAME,
          sortCallback: (direction) => (this.activeSort = { column: TABLE_HEADER.CUSTOMER_NAME, direction }),
          filterActive: !!this.customerSearchFilter,
        },
        {
          header: this.$t('payment.paymentsInProgressTab.headers.totalInvoices'),
          key: TABLE_HEADER.TOTAL_INVOICES,
          width: '130px',
          sortCallback: (direction) => (this.activeSort = { column: TABLE_HEADER.TOTAL_INVOICES, direction }),
        },
        {
          header: this.$t('payment.paymentsInProgressTab.headers.amount'),
          key: TABLE_HEADER.AMOUNT,
          sortCallback: (direction) => (this.activeSort = { column: TABLE_HEADER.AMOUNT, direction }),
        },
        {
          header: this.$t('payment.paymentsInProgressTab.headers.paymentMethod'),
          key: TABLE_HEADER.PAYMENT_METHOD,
          sortCallback: (direction) => (this.activeSort = { column: TABLE_HEADER.PAYMENT_METHOD, direction }),
          filterActive: !!this.paymentMethodSearchFilter,
        },
      ];
    },
    tableData() {
      return this.paymentsInProgress
        .map(({ id: paymentId, customerId, billingLinks, amount, transactions, failed }) => {
          const customer = this.businesses?.find(({ id }) => id === customerId);

          return {
            [TABLE_HEADER.CUSTOMER_NAME]: customer?.name ?? '-',
            [TABLE_HEADER.TOTAL_INVOICES]: billingLinks.length,
            [TABLE_HEADER.AMOUNT]: amount,
            [TABLE_HEADER.PAYMENT_METHOD]: `${this.$t(
              `payment.exports.paymentMethods.shortName.${transactions[0].paymentMethod}`
            )}${transactions[0].directDebit ? ` - ${this.$t('payment.paymentTable.directDebit')}` : ''}`,
            [TABLE_HEADER.INVOICE_DATE]: '-' ?? formatBilledDate(billingLinks),
            paymentId,
            failed,
          };
        })
        .filter(
          ({ customerName, paymentMethod }) =>
            customerName.includes(this.customerSearchFilter) && paymentMethod.includes(this.paymentMethodSearchFilter)
        )
        .sort(getSort(this.activeSort));
    },
  },
  methods: {
    handleRowClick(index) {
      this.selectedPayment = {
        type: 'payment',
        id: this.tableData[index].paymentId,
      };
    },
  },
};
</script>

export const PAYMENT_METHOD_TYPE = {
  CASH: 'cash',
  CREDIT_CARD: 'creditCard',
  BANK_TRANSFER: 'bankTransfer',
  CHEQUE: 'cheque',
};

export const DIRECT_DEBIT_TYPE = {
  BANK_TRANSFER_DIRECT_DEBIT: 'bankTransferDirectDebit',
  CREDIT_CARD_DIRECT_DEBIT: 'creditCardDirectDebit',
};

export const PAYMENT_STATUS = {
  UNPAID: 'unpaid',
  APPROACHING_DUE: 'approachingDue',
  OVERDUE: 'overdue',
};

export const CREDIT_CARD_TYPE = {
  VISA: 'visa',
  MASTERCARD: 'mastercard',
  AMERICAN_EXPRESS: 'americanExpress',
  ISRACARD: 'isracard',
  DINERS: 'diners',
};

export const PAYMENT_STATUS_COLORS = {
  [PAYMENT_STATUS.UNPAID]: 'typography-primary',
  [PAYMENT_STATUS.APPROACHING_DUE]: 'warning',
  [PAYMENT_STATUS.OVERDUE]: 'danger',
};

export const OVERVIEW_DATA_TYPE = {
  TOTAL_FOR_APPROVAL: 'totalForApproval',
  WITHOUT_DUE_TERM: 'withoutDueTerm',
  OVERDUE: 'overdue',
  FUTURE_PAYMENTS: 'futurePayments',
  IN_PROGRESS: 'inProgress',
  TOTAL: 'total',
};

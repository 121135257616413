<template>
  <div>
    <div v-if="!reconciliationStatus">-</div>
    <div v-else class="d-flex gap-2">
      <div class="position-relative" :class="{ ['ms-1']: $direction === 'rtl', ['me-1']: $direction === 'ltr' }">
        <span
          v-if="statusOverride && isAdmin"
          class="position-absolute top-50 start-50 translate-middle override-p rounded-circle center border border-2"
          :class="{
            [`border-${RECONCILIATION_STATUS_COLOR[statusOverride.status]}`]: true,
          }"
        />
        <span
          v-else-if="statusOverride"
          class="position-absolute top-50 start-50 translate-middle p-1 rounded-circle center"
          :class="{ [`bg-${RECONCILIATION_STATUS_COLOR[statusOverride.status]}`]: true }"
        />
        <span
          v-else
          class="position-absolute top-50 start-50 translate-middle p-1 rounded-circle center"
          :class="{
            [`bg-${RECONCILIATION_STATUS_COLOR[reconciliationStatus && reconciliationStatus.status]}`]: true,
          }"
        />
      </div>
      <p class="status">{{ calculatedStatus }}</p>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';

import { RECONCILIATION_STATUS_COLOR } from '@/modules/reconciliation';

export default {
  props: {
    isAdmin: { type: Boolean, default: false },
    reconciliationStatus: { type: Object, default: () => null },
    statusOverride: { type: Object, default: () => null },
  },
  data() {
    return {
      RECONCILIATION_STATUS_COLOR,
      statusPlaceholderWidth: Math.random() > 0.5 ? 'col-9' : 'col-7',
    };
  },
  computed: {
    calculatedStatus() {
      const overrideStatus = this.statusOverride?.status;
      if (overrideStatus) {
        return this.$t(`billing.exports.status.${overrideStatus}`);
      }

      const reconciliationStatus = this.reconciliationStatus?.status;
      if (reconciliationStatus) {
        return this.$t(`billing.exports.status.${reconciliationStatus}`);
      }

      return '';
    },
    statusOverrideModifiedByName() {
      return this.statusOverride?.modifiedByDetails
        ? `${this.statusOverride.modifiedByDetails.firstName} ${this.statusOverride.modifiedByDetails.lastName}`
        : null;
    },
    statusOverrideDate() {
      return this.statusOverride?.modifiedAt
        ? DateTime.fromMillis(this.statusOverride.modifiedAt).toFormat('dd.MM.yy, HH:mm')
        : null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.vector {
  display: none;
}

.status:hover + .vector {
  display: block;
}

.override-p {
  padding: 3.5px !important;
}

.hidden {
  visibility: hidden;
}

.comment-area {
  height: 67px;
}

::v-deep textarea {
  resize: none;
  height: 67px;
}

.reset-button {
  color: #ff385e;
}

.modified-at {
  color: #9295a5;
}

.popover-wrap {
  width: 220px;
}
</style>

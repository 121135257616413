import { DateTime } from 'luxon';
import { isNil } from 'ramda';

import i18n from '@/imports/startup/client/i18n';
import { currency, currencyInteger } from '@/locale/numberConfig';
import { options } from '@/locale/dateConfig';

export function formatMoney(value) {
  if (typeof value === 'number' && !Number.isNaN(value)) {
    const number = Number(value.toFixed(2));
    const currencyOptions = Number.isInteger(number) ? currencyInteger : currency;
    return number.toLocaleString(i18n.locale, currencyOptions);
  }
  return '-';
}

export function formatDate(dateInMs) {
  return dateInMs
    ? new Date(dateInMs).toLocaleDateString(i18n.locale, {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
        timeZone: 'UTC',
      })
    : '-';
}

export function formatDateTime(dateInMs) {
  return dateInMs ? new Date(dateInMs).toLocaleDateString(i18n.locale, options.full) : '-';
}

export function formatBilledDate(billedWithDate, returnDateObject = false) {
  if (!billedWithDate.length || billedWithDate.some(isNil)) return '-';
  if (billedWithDate.length === 1)
    return returnDateObject ? new Date(billedWithDate[0].date) : formatDate(billedWithDate[0].date);

  const dateCount = billedWithDate.reduce((isoMonthGroupsCount, { date: billedDate }) => {
    const isoMonthDate = DateTime.fromJSDate(new Date(billedDate)).toUTC().toFormat('yyyy-LL');
    if (!isoMonthGroupsCount[isoMonthDate]) isoMonthGroupsCount[isoMonthDate] = 0;
    isoMonthGroupsCount[isoMonthDate] += 1;
    return isoMonthGroupsCount;
  }, {});

  let maxDate = null;
  Object.entries(dateCount).forEach(([isoMonthDate, count]) => {
    if (!maxDate) maxDate = isoMonthDate;
    if (count > dateCount[maxDate]) maxDate = isoMonthDate;
  });

  if (returnDateObject) return new Date(maxDate);

  return new Date(maxDate).toLocaleDateString(i18n.locale, {
    month: 'short',
    year: 'numeric',
    timeZone: 'UTC',
  });
}

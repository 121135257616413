import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';
import { gql } from '@apollo/client/core';

import { useNotification } from '@/modules/core';

const PAYMENTS_QUERY = gql`
  query payments($ids: [ID!], $businessId: ID, $supplierId: ID, $completed: Boolean, $isCustomerSide: Boolean) {
    payments(
      ids: $ids
      businessId: $businessId
      supplierId: $supplierId
      completed: $completed
      isCustomerSide: $isCustomerSide
    ) {
      nodes {
        id
        supplierId
        customerId
        requestedDate
        date
        amount
        isRefund
        completed
        billingLinks {
          amount
          billingId
          date
        }
        transactions {
          id
          paymentMethod
          instructionNumber
          reference
          date
          sourceBankAccount {
            bankNumber
            branchNumber
            accountNumber
            accountName
          }
          destinationBankAccount {
            bankNumber
            branchNumber
            accountNumber
            accountName
          }
          directDebit
        }
        failed
        createdAt
        createdBy {
          profile {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const usePaymentsInProgress = (variables, options) => {
  const { error } = useNotification();
  const { result, loading, onError, refetch } = useQuery(PAYMENTS_QUERY, variables, options);

  const paymentConnection = computed(() => result?.value?.payments ?? { nodes: [] });
  const payments = computed(() => paymentConnection.value.nodes);

  onError((err) => {
    console.error('payment - usePaymentsInProgress', err);
    error();
  });

  return {
    payments,
    loading,
    refetch,
  };
};
